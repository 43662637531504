@import './mixins.scss';

body {
  background-color: $black;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
a{
  text-decoration: none;
}
ul{
  list-style: none;
}
*{
  font-family:  'Ubuntu', sans-serif ;
}
h1,h2,h3{
  font-family: 'Rubik Mono One', sans-serif ;
}
code {
  font-family: 'Montserrat', 'Rubik Mono One','Ubuntu', sans-serif ;
}
