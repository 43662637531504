@import "src/mixins";

.form {
  display: flex;
  margin-top: 50vh;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
}

.form-title {
  text-align: center;
  @include h2;
  margin-bottom: 80px;
}

.section-form {
  display: flex;
  background: var(--white-12, rgba(255, 255, 255, 0.12));
  backdrop-filter: blur(15px);
  border-radius: 20px;
  padding: 24px;
  .btn-send {
    @include btn-primary;

  }
  input{
    border: none;
    border-radius: 20px;
    margin-right: 32px;
    padding: 16px;
    background: var(--black-30, rgba(0, 0, 0, 0.30));
    backdrop-filter: blur(15px);
    color: var(--gray, #A7A7A7);
    font: {
      weight: 400;
      size: 16px;
    };
  }
  .input-name{
    padding-right: 100px;
  }
  .input-email{
    padding-right: 100px;
  }
}
.form-img{
  position: absolute;
  top: 795%;
}
.form-blur{
  position: absolute;
  top:760%;
  z-index: -10;
  width: 1000.034px;
  height: 1000.794px;
  flex-shrink: 0;
  border-radius: 100%;
  background: rgba(32, 38, 63, 0.6);
  filter: blur(200px);
}

