@import '../../mixins';

.demo{
  display: flex;
  flex-direction: column;
  background:no-repeat  url('../../../src/img/bg.png') ;
  background-size: cover;
  margin: 0 auto;
  mix-blend-mode: difference;
}
.section-desc{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  .section-title{
    @include h1;
  }
  .section-subtitle{
    color: $white;
    font: {
      weight: 400;
      size: 25px;
    };
    margin-bottom: 64px;
  }
  span{
    @include h1;
    color: $secondary-color;
    font-family: 'Rubik Mono One', sans-serif ;
  }
}
.section-buttons{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 60px;
  .button-demo{
    @include btn-primary;
  }
  .button-info{
    @include btn_simple;
    display: flex;
    margin-left: 40px;
    align-items: center;
    svg{
      margin-right: 8px;
    }
  }
}
.section-imgs{
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: -5;
}

.elipse{
  position: absolute;
  flex-shrink: 0;
  mix-blend-mode: luminosity;
  z-index: -10;
}