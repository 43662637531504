@import "src/mixins";


.accordion {
  display: flex;
  flex-direction: column;
  align-items: center;


  .accordion-title {
    @include h2;
    text-align: center;
    margin-bottom: 100px;
  }
}

.accordion-sub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .sub-title {
    margin-bottom: 30px;
    color: $white;
    font: {
      weight: 700;
      size: 20px;
    };
  }
}

.accordion-desc {
  transition: .3s all;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 82%);
  padding: 40px;
  margin-bottom: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
}

.accordion-content {
  max-width: 500px;
  color: $white;
  padding-bottom: 50px;
  font: {
    weight: 400;
    size: 16px;
  };
}
.accordion-img{
  position: absolute;
  top: 660vh;
  left: 50%;
}
.acc-blur{
  position: absolute;
  z-index: -5;
  width: 1500.034px;
  height: 1500.794px;
  flex-shrink: 0;
  border-radius: 2655.794px;
  background: rgba(32, 38, 63, 0.6);
  filter: blur(200px);
}