@import "src/mixins";

.footer{
  background-color: $white;
  border-radius: 80px 80px 0px 0px;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
}
.section-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $black;
  &-desc{
    color: $black;
    font: {
      weight: 400;
      size: 16px;
    };
  }
}
.section-footer-left{
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.section-footer-right{
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 50px;
  margin-top: 50px;
  a{
    color: $black;
    font: {
      weight: 400;
      size: 16px;
    };
  }
  li{
    margin-bottom: 16px;
  }
}

.footer-logo{
    color: $black;
  margin-bottom: 0;
    font: {
      size: 36px;
      weight: 700;
    }
}
.foot{
  text-align: center;
  padding: 10px;
}